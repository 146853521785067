import React from "react";
import { useTranslation } from "next-i18next";
import Button, { ButtonVariants } from "../../Button";
import styles from "../styles/CookieBanner.module.scss";
import { InitialBannerButtonProps } from "../types";

const InitialBannerButtons = ({
  handleAcceptAll,
  setShowPreferences,
}: InitialBannerButtonProps) => {
  const { t } = useTranslation("common");

  return (
    <div className={styles.buttonWrapper}>
      <Button
        variant={ButtonVariants.Borderless}
        text={t("cookieBanner.buttons.managePreferences")}
        onClick={() => setShowPreferences(true)}
      />
      <Button
        text={t("cookieBanner.buttons.accept")}
        variant={ButtonVariants.Accent}
        onClick={handleAcceptAll}
      />
    </div>
  );
};

export default InitialBannerButtons;
