import React, { useEffect, useState } from "react"
import Cookies from "js-cookie"
import { HandleAcceptSomeParams, PermissionTypes } from "./types"
import ReactGA from "react-ga4"
import { useRouter } from "next/router"

export const useCookieBanner = () => {
  const router = useRouter()
  const [showBanner, setShowBanner] = useState(false)
  const [showPreferences, setShowPreferences] = useState(false)
  const [analyticsAccepted, setAnalyticsAccepted] = useState(false)
  const [advertisingAccepted, setAdvertisingAccepted] = useState(false)
  const necessaryCookiesSlug = "necessaryCookies"
  const analyticsCookiesSlug = "analytics"
  const advertisingCookiesSlug = "advertising"

  const handleRejectAll = () => {
    Cookies.set(necessaryCookiesSlug, PermissionTypes.Granted, { expires: 7 })
    Cookies.set(analyticsCookiesSlug, PermissionTypes.Denied, { expires: 7 })
    Cookies.set(advertisingCookiesSlug, PermissionTypes.Denied, { expires: 7 })
    setAnalyticsAccepted(false)
    setShowBanner(false)
    setAdvertisingAccepted(false)
  }
  const handleAcceptAll = () => {
    Cookies.set(necessaryCookiesSlug, PermissionTypes.Granted, { expires: 30 })
    Cookies.set(analyticsCookiesSlug, PermissionTypes.Granted, { expires: 30 })
    Cookies.set(advertisingCookiesSlug, PermissionTypes.Granted, {
      expires: 30,
    })
    setAnalyticsAccepted(true)
    setAdvertisingAccepted(true)
    setShowBanner(false)
  }
  const handleAcceptSome = ({
    analytics,
    advertising,
  }: HandleAcceptSomeParams) => {
    Cookies.set(necessaryCookiesSlug, PermissionTypes.Granted, { expires: 30 })
    Cookies.set(analyticsCookiesSlug, analytics, { expires: 30 })
    Cookies.set(advertisingCookiesSlug, advertising, { expires: 30 })
    setAnalyticsAccepted(analytics === PermissionTypes.Granted)
    setAdvertisingAccepted(advertising === PermissionTypes.Granted)
    setShowBanner(false)
  }

  useEffect(() => {
    if (router.pathname.includes("cookies-policy")) {
      return setShowBanner(false)
    }
    if (Cookies.get(necessaryCookiesSlug) !== PermissionTypes.Granted) {
      return setShowBanner(true)
    }
  }, [setShowBanner, router.pathname])

  useEffect(() => {
    if (showBanner) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.removeAttribute("style")
    }
  }, [showBanner])

  useEffect(() => {
    if (analyticsAccepted) {
      if (process.env.NEXT_PUBLIC_GA_TRACKING_ID) {
        ReactGA.initialize(process.env.NEXT_PUBLIC_GA_TRACKING_ID)
        ReactGA.send({
          hitType: "pageview",
          page: window.location.pathname + window.location.search,
        })

        const handleRouteChange = (url: string) => {
          ReactGA.send({ hitType: "pageview", page: url })
        }

        router.events.on("routeChangeComplete", handleRouteChange)
        return () => {
          router.events.off("routeChangeComplete", handleRouteChange)
        }
      }
    }
  }, [analyticsAccepted, router])

  return {
    showBanner,
    handleAcceptAll,
    handleAcceptSome,
    handleRejectAll,
    showPreferences,
    setShowPreferences,
    advertisingAccepted,
    setAdvertisingAccepted,
    analyticsAccepted,
    setAnalyticsAccepted,
  }
}
