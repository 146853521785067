import React from "react";
import { useTranslation } from "next-i18next";
import Checkbox from "../../Checkbox";
import { PreferencesCheckboxes } from "../types";
import styles from "../styles/PreferencesCheckboxes.module.scss";

const PreferencesCheckboxes = ({
  advertisingAccepted,
  setAdvertisingAccepted,
  analyticsAccepted,
  setAnalyticsAccepted,
}: PreferencesCheckboxes) => {
  const { t } = useTranslation("common");

  return (
    <div className={styles.preferencesCheckboxes}>
      <form className={styles.checkboxWrapper}>
        <Checkbox
          label={t("cookieBanner.cookieTypes.analytics")}
          onChange={() => setAnalyticsAccepted(!analyticsAccepted)}
          checked={analyticsAccepted}
          name="analytics"
        />
      </form>
      <div className={styles.checkboxWrapper}>
        <Checkbox
          label={t("cookieBanner.cookieTypes.advertising")}
          onChange={() => setAdvertisingAccepted(!advertisingAccepted)}
          checked={advertisingAccepted}
          name="advertising"
        />
      </div>
    </div>
  );
};

export default PreferencesCheckboxes;
