import React from "react";
import { CheckboxProps } from "./types";
import styles from "./Checkbox.module.scss";

const Checkbox = ({ label, onChange, name, checked }: CheckboxProps) => {
  return (
    <label>
      <input
        type="checkbox"
        id={name}
        name={name}
        value={name}
        checked={checked}
        onChange={onChange}
        className={styles.checkboxInput}
      />
      <span className="label">{label}</span>
    </label>
  );
};

export default Checkbox;
