import { useTranslation } from "next-i18next"
import Link from "next/link"
import Modal, { ModalPositions } from "../Modal"
import CookiesSelectionButtons from "./HandlePreferences/CookiesSelectionButtons"
import PreferencesCheckboxes from "./HandlePreferences/PreferencesCheckboxes"
import InitialBannerButtons from "./InitialBanner/InitialBannerButtons"
import styles from "./styles/CookieBanner.module.scss"
import { useCookieBanner } from "./useCookieBanner"

const CookieBanner = () => {
  const { t } = useTranslation("common")
  const {
    handleAcceptAll,
    handleAcceptSome,
    handleRejectAll,
    showBanner,
    showPreferences,
    setShowPreferences,
    setAdvertisingAccepted,
    setAnalyticsAccepted,
    advertisingAccepted,
    analyticsAccepted,
  } = useCookieBanner()

  return showBanner ? (
    <Modal
      isOpen={showBanner}
      hideCloseButton
      position={ModalPositions.Center}
      hasBackdrop
      id="cookie-banner"
    >
      <div className={styles.wrapper}>
        <h3 className={styles.bannerTitle}>
          {t("cookieBanner.cookiesPolicy")}
        </h3>
        <p className={styles.explanation}>
          {t("cookieBanner.explanation")}&nbsp;
          <Link href="/cookies-policy" className={styles.policyLink}>
            {t("cookieBanner.cookiesPolicy")}
          </Link>
          .
        </p>
        {showPreferences && (
          <PreferencesCheckboxes
            setAdvertisingAccepted={setAdvertisingAccepted}
            analyticsAccepted={analyticsAccepted}
            setAnalyticsAccepted={setAnalyticsAccepted}
            advertisingAccepted={advertisingAccepted}
          />
        )}
        {showPreferences ? (
          <CookiesSelectionButtons
            analyticsAccepted={analyticsAccepted}
            advertisingAccepted={advertisingAccepted}
            handleAcceptAll={handleAcceptAll}
            handleAcceptSome={handleAcceptSome}
          />
        ) : (
          <InitialBannerButtons
            handleAcceptAll={handleAcceptAll}
            handleRejectAll={handleRejectAll}
            setShowPreferences={setShowPreferences}
          />
        )}
      </div>
    </Modal>
  ) : null
}

export default CookieBanner
