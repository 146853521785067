import React from "react";
import { useTranslation } from "next-i18next";
import Button, { ButtonVariants } from "../../Button";
import styles from "../styles/CookieBanner.module.scss";
import { CookiesSelectionButtonsProps, PermissionTypes } from "../types";

const CookiesSelectionButtons = ({
  handleAcceptAll,
  handleAcceptSome,
  analyticsAccepted,
  advertisingAccepted,
}: CookiesSelectionButtonsProps) => {
  const { t } = useTranslation("common");

  return (
    <div className={styles.buttonWrapper}>
      <Button
        variant={ButtonVariants.Borderless}
        text={t("cookieBanner.buttons.acceptSelection")}
        onClick={() =>
          handleAcceptSome({
            analytics: analyticsAccepted
              ? PermissionTypes.Granted
              : PermissionTypes.Denied,
            advertising: advertisingAccepted
              ? PermissionTypes.Granted
              : PermissionTypes.Denied,
          })
        }
      />
      <Button
        text={t("cookieBanner.buttons.acceptAll")}
        variant={ButtonVariants.Accent}
        onClick={handleAcceptAll}
      />
    </div>
  );
};

export default CookiesSelectionButtons;
