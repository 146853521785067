export enum PermissionTypes {
  Granted = "granted",
  Denied = "denied",
}

export interface HandleAcceptSomeParams {
  advertising: PermissionTypes;
  analytics: PermissionTypes;
}

export interface InitialBannerButtonProps {
  handleAcceptAll: () => void;
  handleRejectAll: () => void;
  setShowPreferences: (showPreferences: boolean) => void;
}

export interface CookiesSelectionButtonsProps {
  handleAcceptAll: () => void;
  handleAcceptSome: (params: HandleAcceptSomeParams) => void;
  advertisingAccepted: boolean;
  analyticsAccepted: boolean;
}

export interface PreferencesCheckboxes {
  advertisingAccepted: boolean;
  analyticsAccepted: boolean;
  setAdvertisingAccepted: (advertisingAccepted: boolean) => void;
  setAnalyticsAccepted: (analyticsAccepted: boolean) => void;
}
